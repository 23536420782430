<script>
export default {
    name: "template-context",
    props: {
        context: {
            type: Object,
            required: true
        }
    },
}
</script>

<template>
    <div>
        <!-- an information box with a light gray outline and vuetify info icon -->
        <v-alert type="info" outlined>
            <!-- a vuetify icon with a light gray outline -->
            <v-icon icon="mdi-information-outline"></v-icon>
            <!-- a span element with a message -->
            {{ $t('general.template-context-info')}}
        </v-alert>
        <v-simple-table>
            <template v-slot:default>
                <thead>
                <tr>
                    <th>{{$t('general.variable')}}</th>
                    <th>{{$t('general.description')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(description, key) in context" :key="key">
                    <td>{{ key }}</td>
                    <td>{{ description }}</td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<style scoped>

</style>
